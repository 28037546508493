import {
	type LoaderFunctionArgs,
	redirect,
	type MetaFunction,
} from '@remix-run/node';
import { bannerImage } from '#app/components/img/index.ts';
import { Logo } from '#app/components/logo/logo.tsx';
import { Spacer } from '#app/components/spacer.tsx';
import { isAdminPresent } from '#app/utils/admin.server.ts';

export const meta: MetaFunction = () => [{ title: 'Company Run' }];

export async function loader({}: LoaderFunctionArgs) {
	if (!(await isAdminPresent())) return redirect('/onboarding-admin');
	return {};
}

export default function Index() {
	return (
		<div className="flex flex-col justify-between sm:container sm:py-6">
			<main className="font-poppins grid h-full place-items-center">
				<div className="relative shadow-xl sm:overflow-hidden sm:rounded-2xl">
					<div className="absolute inset-0">
						<img
							className="h-full w-full object-cover"
							src={bannerImage}
							alt="Company Run banner"
						/>
						<div className="absolute inset-0 bg-[color:rgba(30,23,38,0.35)] mix-blend-multiply" />
					</div>
					<div className="lg:pt-18 relative px-4 py-14 sm:px-6 sm:pb-14 sm:pt-16 lg:px-8 lg:pb-20">
						<h1 className="text-center text-6xl font-extrabold tracking-tight sm:text-8xl lg:text-9xl">
							<span className="uppercase text-white drop-shadow-md">
								<span>Company Run</span>
								<Logo className="mx-auto" />
							</span>
						</h1>
						<Spacer size="md" />
						<p className="mx-auto text-center text-lg text-white sm:max-w-3xl lg:text-xl">
							The platform to manage your corporate runs.
						</p>
					</div>
				</div>
			</main>
		</div>
	);
}
